import loadable from '@loadable/component';
import React from 'react';

import { bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  function buildPageAssetsData() {
    const data = pageAssetsData?.[camelize(ASSET_NAME)]?.data;
    if (!data) return data;

    /// section 'Welcome to ORLO medical'
    data.sections[0] = {
      ...data.sections[0],
      blocks: (() => {
        const blocks = data.sections[0].blocks;
        blocks.forEach((b) => b.blockType = 'ctaBlock');
        blocks[0].style = 'primary';
        blocks[1].style = 'secondary';
        return blocks
      })(),
    };

    /// section 'Browse Products by Categories'
    data.sections[1].sectionType = 'chips';
    data.sections[1] = {
      ...data.sections[1],
      blocks: (() => {
        const blocks = data.sections[1].blocks;
        blocks.forEach((b) => b.blockType = 'chipBlock');
        return blocks;
      })(),
    }

    /// section 'Orchestrating Data & AI'
    data.sections[2].sectionType = 'card';
    data.sections[2] = {
      ...data.sections[2],
      blocks: (() => {
        const blocks = data.sections[2].blocks;
        blocks.forEach((b) => b.blockType = 'featureBlock');
        return blocks;
      })(),
    }

    /// section 'Our Services'
    data.sections[3].sectionType = 'card';
    data.sections[3] = {
      ...data.sections[3],
      imageUrl: `${process.env.PUBLIC_URL}/orlo-logo.jpeg`,
      blocks: (() => {
        const blocks = data.sections[3].blocks;
        blocks.forEach((b) => b.blockType = 'textBlock');
        return blocks;
      })(),
      customFooter: true,
    }

    return data;
  }

  return (
    <PageBuilder
      pageAssetsData={buildPageAssetsData()}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
